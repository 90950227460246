import styled from "styled-components";


export const Container = styled.div`
`;

export const MainContainer = styled.section`
  margin-left: calc(250px + 1rem);
  margin-top: calc(50px + 2rem);
  margin-right: 1rem;
  margin-bottom: 1rem;

  @media (max-width: 879px){
    margin-left: 1rem;
    margin-top: calc(50px + 1rem);
  }

`;