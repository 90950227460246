import { useEffect, useRef, useState } from "react";
import Button from "../../../components/Button";
import DataTable from "../../../components/DataTable";
import { Container } from "./styles";
import api from "../../../services/api";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { colors } from "../../../constants/colors";
import { InputGroup } from "../../../components/InputGroup";
import { useNavigate, useNavigation } from "react-router-dom";
import axios from "axios";
import { Section } from "../../../components/Section/styles";
import { InputMask } from "../../../components/InputMask";
import Papa from "papaparse";
import moment from "moment";
import { toast } from "react-toastify";
import { cnpjMask } from "../../../utils";

const EditImport = () => {

  const [ name, setName ] = useState('');
  const [ bulk, setBulk ] = useState([{ cnpj: '', dataInicial: '', dataFinal: '', uf: 'MT'}]);
  const [ loading, setLoading ] = useState(false);
  const [ webhook, setWebhook ] = useState('');

  const fileRef = useRef();

  const handleCsv = (event) => {
    if(!event.target.files[0]) return;
    Papa.parse(event.target.files[0], {
      header: true,
      skipEmptyLines: true,
      complete: function (results) {
        const data = results.data.map(d => ({
          ...d, 
          dataInicial: moment(d.dataInicial, 'DD/MM/YYYY').format('YYYY-MM-DD'),
          dataFinal: moment(d.dataFinal, 'DD/MM/YYYY').format('YYYY-MM-DD'),
          uf: d?.uf ? d.uf : 'MT'
        }));
        setBulk(data);
      },
    });
  };

  useEffect(() => {
    fetchData()
  }, []);


  const fetchData = async () => {
    try{
      const { data } = await api.get('meta/webhook');
      setWebhook(data.value);
    }catch(e){
      
    }
  }

  const navigate = useNavigate();

  const onSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);

    try{

      const _bulk = bulk.map((b) => {
        return {
          ...b, 
          dataInicial: moment(b.dataInicial).format('DD/MM/YYYY'),
          dataFinal: moment(b.dataFinal).format('DD/MM/YYYY'),
          cnpj: cnpjMask(b.cnpj)
        }
      })

      if(_bulk.some(b => b.cnpj.replace(/\D+/gi, '').length < 14)){
        toast('Há CNPJs incompletos', {
          type: "error"
        });
        return;
      }
      if(_bulk.some(b => moment(b.dataInicial, 'DD/MM/YYYY') > moment(b.dataFinal, 'DD/MM/YYYY'))){
        toast('Data inicial não pode ser maior que data final', {
          type: "error"
        });
        return;
      }

      if(_bulk.some(b => moment(b.dataFinal, 'DD/MM/YYYY').diff(moment(b.dataInicial, 'DD/MM/YYYY'), 'days') > 31)){
        toast('Invervalo de datas não pode ser maior que 31 dias', {
          type: "error"
        });
        return;
      }
      
      const { data } = await api.post('/process-data', {
        name,
        bulk: _bulk
      });

      toast('Adicionado à fila', {
        type: "success"
      });
      navigate('/importacoes/'+data.id);
    }catch(e){
    }finally{
      setLoading(false);
    }

  }

  const handleChangeBulk = (v, index, k) => {
    const _bulk = [...bulk];

    _bulk[index][k] = v;
    setBulk(_bulk);
  }

  const removeRow = (index) => {
    if(bulk.length == 1) return
    const _bulk = [...bulk];
    _bulk.splice(index, 1)
    setBulk(_bulk);
  }

  const addRow = () => {
    setBulk([...bulk, {cnpj: '', dataInicial: '', dataFinal: '', uf: 'MT'}]);
  }

  const importCsv = () => {
    fileRef.current.type = 'text';
    fileRef.current.type = 'file';
    fileRef.current.click();
  }

  

  return <Container>
    <h5 className="section-title mb-0" style={{fontWeight: 700}}>Nova Importação</h5>

    <Section className="mt-3 ">
      <form onSubmit={onSubmit}>

        <InputGroup
          label={'Nome'}
          value={name}
          onChange={setName}
          required
          placeholder={'Insira o nome'}
        />

        <InputGroup
          label={'Webhook'}
           value={webhook}
          required
           onChange={setWebhook}
          placeholder={'Se não quiser pode usar o global'}
        />
        {/* aqui vai url webhook */}
        <div className="bulk-wrapper">
          {bulk.map((row, index) => {
            return <div key={index} className="bulk-container p-3">
              <div class="row">
                <div class="col-12 col-sm-6">
                  <div class="row">
                  <InputMask
                    mask={'99.999.999/9999-99'}
                    className="col-8 input-sm"
                    required
                    containerStyle={{marginTop: 5}}
                    label={'CNPJ'}
                    value={row.cnpj}
                    onChange={(v)=>handleChangeBulk(v, index, 'cnpj')}
                    placeholder={'Insira o CNPJ'}
                  />
                  <InputGroup
                    className="col-4 input-sm"
                    containerStyle={{marginTop: 5}}
                    label={'UF'}
                    required
                    type="select"
                    value={row.uf}
                    options={[
                      { title: 'DF', value: 'DF' },
                      { title: 'ES', value: 'ES' },
                      { title: 'MG', value: 'MG' },
                      { title: 'RJ', value: 'RJ' },
                      { title: 'SC', value: 'SC' },
                      { title: 'SP', value: 'SP' },
                      { title: 'AC', value: 'AC' },
                      { title: 'AL', value: 'AL' },
                      { title: 'AM', value: 'AM' },
                      { title: 'BA', value: 'BA' },
                      { title: 'CE', value: 'CE' },
                      { title: 'GO', value: 'GO' },
                      { title: 'MA', value: 'MA' },
                      { title: 'PA', value: 'PA' },
                      { title: 'PE', value: 'PE' },
                      { title: 'PI', value: 'PI' },
                      { title: 'RO', value: 'RO' },
                      { title: 'SE', value: 'SE' },
                      { title: 'TO', value: 'TO' },
                      { title: 'MS', value: 'MS' },
                      { title: 'RN', value: 'RN' },
                      { title: 'RS', value: 'RS' },
                      { title: 'AP', value: 'AP' },
                      { title: 'MT', value: 'MT' },
                      { title: 'PB', value: 'PB' },
                      { title: 'PR', value: 'PR' },
                      { title: 'RR', value: 'RR' },
                    ]}
                    onChange={(v)=>handleChangeBulk(v, index, 'uf')}
                    placeholder={'UF'}
                  />
                  </div>
                </div>
                <div class="col-12 col-sm-6">
                  <div class="row">
                    <InputGroup
                      required
                      className="col-6 input-sm"
                      containerStyle={{marginTop: 5}}
                      label={'Data Inicial'}
                      type="date"
                      value={row.dataInicial}
                      onChange={(v)=>handleChangeBulk(v, index, 'dataInicial')}
                      placeholder={'Data Inicial'}
                    />
                    <InputGroup
                      className="col-6 input-sm"
                      containerStyle={{marginTop: 5}}
                      label={'Data Final'}
                      required
                      type="date"
                      value={row.dataFinal}
                      onChange={(v)=>handleChangeBulk(v, index,'dataFinal')}
                      placeholder={'Data Final'}
                    />
                  </div>
                </div>
              </div>
              <div className="d-flex justify-content-end mt-2">
                <button type="button" className="btn btn-neutral btn-sm btn-remove" onClick={()=>removeRow(index)}><FontAwesomeIcon icon="times"/> Remover</button>
              </div>
            </div>
          })}
        </div>
        <div className="d-flex justify-content-end" style={{gap: 10}}>
          <input ref={fileRef} type="file" style={{position: 'absolute', opacity: 0, zIndex: -1}} onChange={handleCsv}/>
          <button type="button" className="btn btn-neutral btn-sm btn-add" onClick={importCsv}><FontAwesomeIcon icon="cloud-arrow-up"/> Importar CSV</button>
          
          <button type="button" className="btn btn-neutral btn-sm btn-add" onClick={addRow}><FontAwesomeIcon icon="plus"/> Adicionar CNPJ</button>
        </div>


        <Button type="submit" loading={loading} loadingText={'Adicionando à fila...'} className="btn-primary mt-3">Salvar</Button>
      </form>
    </Section>
  </Container>
}

export default EditImport;