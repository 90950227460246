import styled from "styled-components";
import { colors } from "../../../constants/colors";


export const Container = styled.section`

  .bulk-wrapper {
    max-height: 400px;
    overflow: auto;
    margin-top: 1rem;
    margin-bottom: 1rem;
  }

  .bulk-container{
    padding: 0px 0 1.5rem 0;
    margin: 10px 0px;
    border-radius: 14px;
    border: 1px dashed ${colors.primaryColor}66;
  }

  .btn-add{
    color: ${colors.primaryColor};
    font-weight: 600;
    font-size: 12px;
    padding: 0;
    &:focus{
      box-shadow: none;
    }
  }
  .btn-remove{
    color: ${colors.red};
    font-weight: 600;
    font-size: 12px;
    padding: 0;
    &:focus{
      box-shadow: none;
    }
  }

`;