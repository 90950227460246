import styled from "styled-components";
import { colors } from "../../constants/colors";


export const Container = styled.div`

  &.input-sm,.form-control{
    font-size: 13px;
  }

  label{
    font-weight: 600;
    color: black;
  }
  
  .form-control{
    border-radius: 14px;
    padding: 10px 15px;
    &::placeholder{
      color: #ccd;
    }
    &:focus{
      box-shadow: none;
    }
  }

`;