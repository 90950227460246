import { useEffect, useState } from "react";
import Button from "../../components/Button";
import { Container } from "./styles";
import api from "../../services/api";
import { InputGroup } from "../../components/InputGroup";
import { useNavigate } from "react-router-dom";
import { Section } from "../../components/Section/styles";
import { toast } from "react-toastify";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {faEye, faEyeSlash} from "@fortawesome/free-solid-svg-icons";

const levelOps = [
  {title: 'Administrador', value: '1'},
  {title: 'Gerenciar pontos de coleta', value: '2'}
];

const Settings = () => {
  const [ sefazUser, setSefazUser ] = useState('');
  const [ password, setPassword ] = useState('');
  const [ showPassword, setShowPassword] = useState(false);
  const [ webhook, setWebhook ] = useState('');
  const [ loading, setLoading ] = useState(false);

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  useEffect(() => {
    fetchData()
  }, []);

  const fetchData = async () => {
    try{
      const { data } = await api.get('metas');
      setWebhook(data.find(m => m.key == 'webhook')?.value);
      setSefazUser(data.find(m => m.key == 'sefaz_user')?.value);
      setPassword(data.find(m => m.key == 'sefaz_password')?.value);
    }catch(e){
      
    }
  }

  const onSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    try{
      
      await api.post('/metas', {
        webhook: webhook,
        sefaz_user: sefazUser,
        sefaz_password: password
      });
      
      toast('Salvo com sucesso', { type: 'success' });
      // navigate('/usuarios');
    }catch(e){
      toast('Erro ao salvar: ' + e.message, { type: 'error'});
      console.log(e);
    }
    setLoading(false);

  }

  return <Container>
    <h5 className="section-title mb-0" style={{fontWeight: 700}}>Configurações</h5>

    <Section className="mt-3 flex flex-column">
      <h5 className="mb-3"><FontAwesomeIcon icon="gear"/> Gerais</h5>
      <form onSubmit={onSubmit}>

        <InputGroup
          label={'Webhook'}
          value={webhook}
          required
          onChange={setWebhook}
          placeholder={'Link para envio após conclusão de importação'}
        />

        <div className="row">
            <InputGroup
              label={'Usúario do sefaz'}
              placeholder={'nome'}
              value={sefazUser}
              required
              onChange={setSefazUser}
              className="mt-2 col-sm"
            />
            
            <div className="position-relative mt-2 col-sm">
              <InputGroup
                className="flex align-items-center"
                label={'Senha'}
                placeholder={'password'}
                value={password}
                onChange={setPassword}
                type={showPassword ? 'text' : 'password'}
              /> 
                <FontAwesomeIcon style={{top: '2.3rem', right:'1.3rem', position: 'absolute'}} icon={showPassword ? faEyeSlash : faEye} onClick={togglePasswordVisibility}/>
            </div> 
        </div>     

        <Button type="submit" loading={loading} loadingText={'Salvando...'} className="btn-primary mt-3">Salvar</Button>
      </form>
    </Section>
  </Container>
}

export default Settings;