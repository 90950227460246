import styled from "styled-components";
import { colors } from "../../constants/colors";


export const Container = styled.aside`
  width: 230px;
  height: 400px;
  position: fixed;
  border-radius: 14px;
  top: calc(50px + 2rem);
  left: 20px;
  background-color: ${colors.white};
  box-shadow: 0px 0px 10px #00000011;
  padding: 1rem 0;
  z-index: 10;
  transition: left 0.2s ease-in-out, opacity 0.1s ease-in-out;

  @media (max-width: 879px){
    left: -250px;
    opacity: 0;

    &.open{
      padding: 2rem 0;
      align-self: center;
      left: calc(50% - 115px);
      right: 0;
      opacity: 1;

    }

    .close{
      display: block !important;
    }
  }
  
  .close{
    display: none;
    cursor: pointer;
    position: absolute;
    right: 1rem;
    top: 1rem;
  }

  .sidebar-title {
    font-weight: 700;
    color: #333333;
  }

  .sidebar-subtitle {
    margin-left: 1rem;
    color: #888888;
    font-size: 0.85rem;
  }

  .nav-link{
    color: #333;
    font-weight: 500;
    font-size: 0.9rem;
    margin-bottom: 0.5rem;
    padding-top: 0.6rem;
    padding-bottom: 0.6rem;
    border-radius: 8px;

    &.active{
      background-color: #ebf3fe;
      color: ${colors.primaryColor};
    }

    &:hover{
      background-color: #d0dff4;
      color: ${colors.primaryColor};

    }
    .icon{
      margin-right: 0.5rem;
    }
  }

  .brand-logo{
    width: 80px;
    margin-top: 2rem;
    margin-bottom: 2rem;
  }

`;

