import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Container } from "./styles";
import { Link, NavLink, useLocation, useNavigate } from "react-router-dom";
import IconButton from "../IconButton";
import { useEffect, useState } from "react";
import jwtDecode from 'jwt-decode';


const Sidebar  = ({open, onClose}) => {

  const [ level, setLevel ] = useState("1");

  const navigate = useNavigate();

  useEffect(() => {
    const token = localStorage.getItem('access_token');
    if(token){
      const decoded = jwtDecode(token);
      setLevel(decoded.level);
    }
  }, []);

  const location = useLocation();

  const logout = () => {
    localStorage.removeItem('access_token');
    navigate('/login');
  }

  return <Container className={open ? 'open' : ''}>
    
    <FontAwesomeIcon onClick={onClose} className={'close'} icon={'close'} />

    <div className="p-3 pt-2 h-100 d-flex flex-column justify-content-between">
      <div>
        <NavLink to={'/importacoes'} onClick={onClose} className={"nav-link"} active={location.pathname.startsWith('/importacoes')}>
          <FontAwesomeIcon className={'icon'} icon={'cloud-arrow-up'} />
          <span>Lista de Importação</span>
        </NavLink>
        <NavLink to={'/consulta-nfes'} onClick={onClose} className={"nav-link"} active={location.pathname.startsWith('/consulta-nfes')}>
          <FontAwesomeIcon className={'icon'} icon={'table-list'} />
          <span>Consultar NFEs</span>
        </NavLink>
        <NavLink to={'/configuracoes'} onClick={onClose} className={"nav-link"} active={location.pathname.startsWith('/configuracoes')}>
          <FontAwesomeIcon className={'icon'} icon={'sliders'} />
          <span>Configurações</span>
        </NavLink>
        {/* {level === "1" && <NavLink to={'/usuarios'} className="nav-link" active={location.pathname.startsWith('/usuarios')}>
          <FontAwesomeIcon className={'icon'} icon={'user'} />
          <span>Usuários</span>
        </NavLink>} */}
      </div>

      <NavLink to={'/login'} onClick={() => logout()} className="nav-link mt-5" active={"0"}>
        <FontAwesomeIcon className={'icon'} icon={'power-off'} />
        <span>Sair</span>
      </NavLink>


    </div>

  </Container>;
} 

export default Sidebar;