import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Backdrop, Container } from "./styles";
import { Link, NavLink, useLocation, useNavigate } from "react-router-dom";
import IconButton from "../IconButton";
import { useEffect, useState } from "react";
import jwtDecode from 'jwt-decode';
import { InputGroup } from "../InputGroup";
import { toast } from "react-toastify";
import api from "../../services/api";
import Button from "../Button";


const CaptchaModal  = ({data, open, onClose}) => {

  const [ level, setLevel ] = useState("1");
  const [ text, setText ] = useState('');
  const [ loading, setLoading ] = useState();

  const navigate = useNavigate();

  useEffect(() => {

  }, []);

  const submit = async () => {
    setLoading(true);
    try{
      const response = await api.post('captcha', {
        text
      });
      
      if(response.status == 200){
        toast('Captcha validado!', { type: 'success' });
        onClose()
      }
    }catch(e){
      toast(e?.response?.data?.message || e?.message, { type: 'error' });
    }finally{
      setText('');
      setLoading(false);
    }
  }


  return <>
    <Container className={open ? 'open' : ''}>
      
      <FontAwesomeIcon onClick={onClose} className={'close'} icon={'close'} />

      <div className="mt-5 px-3 d-flex flex-column">
        <img src={data?.captcha} width="100%" />

        <InputGroup
          // label={'Webhook'}
          value={text}
          required
          onChange={setText}
          placeholder={'Link para envio após conclusão de importação'}
          />
        <Button type="button" onClick={submit} loading={loading} loadingText={'Verificando...'} className="btn-primary mt-3">Enviar</Button>
      </div>

    </Container>
    <Backdrop className={open ? 'active' : ''} />
  </>
} 

export default CaptchaModal;