/* eslint-disable no-restricted-globals */
/* eslint-disable no-undef */
// import { Container } from "./styles";
import React, {useEffect, useRef, useState} from "react";
import { Container } from "./styles";
import Button from "../../components/Button";
import { colors } from "../../constants/colors";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { CardInfo } from "./components/CardInfo";
import Listagemcnpj from "./components/ListagemCnpj";
import api from "../../services/api";
import IconButton from "../../components/IconButton";
import { useNavigate, useParams } from "react-router";
import moment from "moment";
import Spinner from "../../components/Spinner";
import DataTable from "../../components/DataTable";

const statusList = {
pending: {
    class: 'alert-warning',
    title: 'Pendente'
},
processing: {
    class: 'alert-info',
    title: 'Em progresso'
},
completed: {
    class: 'alert-success',
    title: 'Finalizado'
},
canceled: {
    class: 'alert-danger',
    title: 'Cancelado'
},
}

const alias = {
    serie: 'Série',
    numero_nf: 'Número Nota Fiscal',
    chave_acesso: 'Chave de Acesso',
    natureza_operacao: 'Natureza da Operação',
    tipo_emissao: 'Tipo de Emissão',
    data_emissao: 'Data da Emissão',
    data_autorizacao: 'Data da Autorização',
    protocolo: 'Protocolo',
    situacao: 'Situação',
    cnpj_emissor: 'CNPJ do Emissor',
    razao_social_emissor: 'Razão Social do Emissor',
    ie_emissor: 'Inscrição Estadual do Emissor',
    fantasia_emissor: 'Fantasia do Emissor',
    uf_emissor: 'UF do Emissor',
    cnpj_destinatario: 'CNPJ do Destinatário',
    razao_social_destinatario: 'Razão Social do Destinatário',
    ie_destinatario: 'Inscrição Estadual do Destinatário',
    // fantasia_destinatario: 'Fantasia do Destinatário',
    uf_destinatario: 'UF do Destinatário',
    valor_total_base: 'Valor Total de Base',
    valor_total_icms: 'Valor Total do ICMS',
    valor_total_bc: 'Valor Total BC',
    valor_total_icms_st: 'Valor Total ICMS ST',
    valor_total_produto: 'Valor Total do Produto',
    valor_total_frete: 'Valor Total do Frete',
    valor_total_nf: 'Valor Total da Nota Fiscal',
    valor_total_servico: 'Valor Total do Serviço'
  }

const Detalhes = () => {
    const [ loading, setLoading ] = useState(false);
    const [ queue, setQueue ] = useState();
    const [ nfes, setNfes ] = useState([]);

    const [activeTab, setActiveTab] = useState('lista');  

    const [ columns, setColumns ] = useState([]);
    const [ page, setPage ] = useState(1);

    useEffect(() =>{
        setColumns( Object.keys(alias).map(k => ({title: alias[k], key: k})));
      }, []);

    const navigate = useNavigate();

    const { id } = useParams();

    const handleTabChange = (tab) => {
        setActiveTab(tab);
    };

    useEffect(() => {
        if(id){
            fetchData()
        }
    }, [id]);


    const fetchData = async () => {
        setLoading(true);
        await Promise.all([fetchFila()]);
        setLoading(false);

    }

    const interval = useRef();

    const fetchFila =  async (ignoreNfes = false) => {
        try {
            const { data } = await api.get("/filas/"+id);
            setQueue(data)
            if(!ignoreNfes) await fetchAllNfes(data);

            if(ignoreNfes && data.status != 'pending'){
                interval.current && clearInterval(interval.current)
            }
        }   catch (error) {
            console.error("Error :", error);
        }
    };

    const fetchAllNfes =  async (queue) => {
        try {
            let nfes = [];
            for(let q of queue.Queries){
                const { data } = await api.get("/consulta-nfe/", {
                    params: {
                        cnpj: q.cnpj,
                        data_inicial: moment(q.startDate).format('DD/MM/YYYY'),
                        data_final: moment(q.endDate).format('DD/MM/YYYY'),
                    }
                });

                nfes = [...nfes, ...data];
            }
            setNfes(nfes);
            console.log(nfes)
        }   catch (error) {
            console.error("Error :", error);
        }
    };


    const handleRetry = async () => {
        try{
            await api.post(`/filas/${id}/retry`);
            await fetchData();
            handleInterval();
        }catch(e){
            console.log(e)
        }
    }

    const handleInterval = () => {
        interval.current && clearInterval(interval.current)
        
        interval.current = setInterval(async () => {
            await fetchFila(true)
        }, 2000);

        return () => interval.current && clearInterval(interval.current);
    }

    useEffect(() => {
        handleInterval();
    }, []);


    return (
        <>
        {(loading || !queue) ? 
            <Spinner/>
            :
        <>
            <Container className="container-fluid bg-primary  w-100" style={{padding: "1rem 1rem 100px 1rem", borderRadius: '15px'}}>
                <div className="d-flex align-items-center flex-row">
                    <IconButton inverted style={{marginRight: 10}} onClick={() => navigate('/importacoes')}><FontAwesomeIcon icon="fa fa-chevron-left"/></IconButton>
                    <h5 style={{fontWeight:700, color: 'white'}} className="section-title m-0">Detalhes "{queue.name}"</h5>
                </div>
                    {/* <div className="rounded-3 p-1 bg-white d-flex flex-column "> */} 
                <div className="m-2 p-2 d-flex gap-3 h-100 flex-wrap">
                    <CardInfo 
                        title={'Status'} 
                        icon={<FontAwesomeIcon icon="fa-solid fa-signal" className="color-primary" style={{color: colors.primaryColor, backgroundColor: 'primary'}} />}
                        value={<span style={{fontSize: 16}} class={`badge ${statusList[queue.status].class}`} >{statusList[queue.status].title}</span>}
                        />
                    <CardInfo 
                        title={'Processados'} 
                        icon={<FontAwesomeIcon icon="fa-solid fa-bars-progress" className="color-primary" style={{color: colors.primaryColor, backgroundColor: 'primary'}} />}
                        value={queue.Queries.filter(q => q.status == 'completed').length}
                        description={'processados'}
                        />
                    <CardInfo 
                        title={'NFEs importados'} 
                        icon={<FontAwesomeIcon icon="fa-solid fa-cloud-arrow-up" className="color-primary" style={{color: colors.primaryColor, backgroundColor: 'primary'}} />}
                        value={nfes.length}
                        description={'notas importadas'}
                        />
                </div>
            </Container>
            <Container className="container-fluid d-flex flex-column p-3  justify-content-center" style={{marginTop: -110}}>
                
                <div className="card d-flex gap-5 p-3 align-content-center m-3" style={{borderRadius: 10}}>
                    <nav>
                        <div class="nav nav-tabs" id="nav-tab" role="tablist">
                            <button className={`nav-link  ${activeTab === 'lista' ? 'border-0 bg-primary text-white active': 'bg-white text-primary'}`} onClick={() => handleTabChange('lista')} id="nav-home-tab" data-bs-toggle="tab" data-bs-target="#nav-home" type="button" role="tab" aria-controls="nav-home" aria-selected="true" style={{fontWeight:700}}>Lista</button>
                            <button className={`nav-link ${activeTab === 'visualizacoes' ? 'border-0 bg-primary text-white active': 'bg-white text-primary'}`} onClick={() => handleTabChange('visualizacoes')}   id="nav-profile-tab" data-bs-toggle="tab" data-bs-target="#nav-profile" type="button" role="tab" aria-controls="nav-profile" aria-selected="false" style={{fontWeight:700}}>Vizualizações</button>
                        </div>
                    </nav>
                    <div class="tab-content" id="nav-tabContent">
                        <div class="tab-pane fade show active" id="nav-home" role="tabpanel" aria-labelledby="nav-home-tab" tabindex="0">
                            {(queue.status != 'pending' && queue.Queries.some(q => q.status == 'pending')) &&
                                <Button onClick={()=>handleRetry()} className="btn-warning btn-sm" style={{whiteSpace: 'nowrap'}}>Tentar novamente</Button>}
                            <Listagemcnpj queue={queue} onRetry={handleRetry} cnpjs={queue.Queries} />
                        </div>
                        <div class="tab-pane fade" id="nav-profile" role="tabpanel" aria-labelledby="nav-profile-tab" tabindex="0">
                        <h6>As notas carregadas aqui se referem a consulta geral de todos os registros.</h6>
                        <DataTable
                            className="mt-3"
                            columns={columns}
                            setColumns={setColumns}
                            data={nfes}
                            onSubmitSearch={(v) => fetchData()}
                            // topActions={[
                            //   <Button loadingText={'Aguarde! Exportando...'} className={'btn-primary'} onClick={()=>selectAll()}>
                            //     { 
                            //       'Selecionar todos' 
                            //     }
                            //   </Button>,
                            //   <Button loadingText={'Aguarde! Exportando...'} className={'btn-primary'} onClick={()=>exportAll()}>
                            //     { 
                            //       queues.some(p => p.selected) ? 'Exportar ' + queues.filter(p => p.selected).length + ' selecionados' : 'Exportar todos' 
                            //     }
                            //   </Button>,
                            //   queues.some(p => p.selected) && <Button loading={deleteSelectedLoading} style={{color: 'red'}} loadingText={'Aguarde! Excluindo...'} className={'btn-outline'} onClick={()=>deleteSelected()}>
                            //     { 
                            //       'Excluir ' + queues.filter(p => p.selected).length + ' selecionados'
                            //     }
                            //   </Button>,
                            // ]}
                            // onSort={fetchData}
                            page={page}
                            onChangePage={setPage}
                            />

                            {/* <div className="d-flex justify-content-end m-3">
                                <Button  className="btn-primary mt-3" style={{borderRadius: 14, fontSize: 14, fontWeight: 600,}}>Exportar para CSV &nbsp;<FontAwesomeIcon icon="file" /></Button>
                            </div> */}
                        </div>
                    </div>
                    
                </div>
                
            </Container>
        </>
        }
        </>
    
            
        
    )
}
export default Detalhes;