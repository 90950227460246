import styled from "styled-components";
import { colors } from "../../../constants/colors";


export const Container = styled.section`

  .last-update{
    font-size: 14px;

  }
`;