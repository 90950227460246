import React, { useEffect, useRef, useState } from "react";
import Button from "../../../components/Button";
import DataTable from "../../../components/DataTable";
import { Container } from "./styles";
import api from "../../../services/api";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { colors } from "../../../constants/colors";
import { useNavigate } from "react-router-dom";
import * as FileSaver from 'file-saver';
import * as XLSX from 'xlsx';
import { Section } from "../../../components/Section/styles";
import Spinner from "../../../components/Spinner";
import InputButton from "../../../components/IconButton";
import moment from 'moment';
import InputSearch from "../../../components/InputSearch";
import Progress from "../../../components/Progress";
import { InputMask } from "../../../components/InputMask";
import { InputGroup } from "../../../components/InputGroup";

const statusList = {
  pending: {
    class: 'alert-warning',
    title: 'Pendente'
  },
  processing: {
    class: 'alert-info',
    title: 'Em progresso'
  },
  completed: {
    class: 'alert-success',
    title: 'Sucesso'
  },
  canceled: {
    class: 'alert-danger',
    title: 'Cancelado'
  },
}

const alias = {
  serie: 'Série',
  numero_nf: 'Número Nota Fiscal',
  chave_acesso: 'Chave de Acesso',
  natureza_operacao: 'Natureza da Operação',
  tipo_emissao: 'Tipo de Emissão',
  data_emissao: 'Data da Emissão',
  data_autorizacao: 'Data da Autorização',
  protocolo: 'Protocolo',
  situacao: 'Situação',
  cnpj_emissor: 'CNPJ do Emissor',
  razao_social_emissor: 'Razão Social do Emissor',
  ie_emissor: 'Inscrição Estadual do Emissor',
  fantasia_emissor: 'Fantasia do Emissor',
  uf_emissor: 'UF do Emissor',
  cnpj_destinatario: 'CNPJ do Destinatário',
  razao_social_destinatario: 'Razão Social do Destinatário',
  ie_destinatario: 'Inscrição Estadual do Destinatário',
  // fantasia_destinatario: 'Fantasia do Destinatário',
  uf_destinatario: 'UF do Destinatário',
  valor_total_base: 'Valor Total de Base',
  valor_total_icms: 'Valor Total do ICMS',
  valor_total_bc: 'Valor Total BC',
  valor_total_icms_st: 'Valor Total ICMS ST',
  valor_total_produto: 'Valor Total do Produto',
  valor_total_frete: 'Valor Total do Frete',
  valor_total_nf: 'Valor Total da Nota Fiscal',
  valor_total_servico: 'Valor Total do Serviço'
}

const Consulta = () => {

  const [ page, setPage ] = useState(1);
  const [ queues, setQueues ] = useState([]);
  const [ loading, setLoading ] = useState(false);
  const [ exportAllLoading, setExportAllLoading ] = useState(false);
  const [ deleteSelectedLoading, setDeletedSdeleteSelectedLoading ] = useState(false);
  const [ columns, setColumns ] = useState([]);
  const [ lastUpdate, setLastUpdate ] = useState();
  const [ search, setSearch ] = useState('');
  const [ cnpj, setCnpj ] = useState('');
  const [ tipo, setTipo ] = useState('');
  const [ dataInicial, setDataInicial ] = useState('');
  const [ dataFinal, setDataFinal ] = useState('');


  useEffect(() =>{
    setColumns( Object.keys(alias).map(k => ({title: alias[k], key: k})));

    
  }, []);

  const searchDebounce = useRef();

  useEffect(() => {
    
    if(searchDebounce.current) clearTimeout(searchDebounce.current);
    searchDebounce.current = setTimeout(() =>{
      fetchData();
    }, 300);

  }, [ search ]);
//  função para buscar dados na api de nfe
  const fetchData = async (sortBy = null) => {
    setLoading(true);
    const sortObj = sortBy ? { sort: sortBy } : {};
    try{
      setPage(1);
      const { data } = await api.get('consulta-nfe', {
        params:{
          cnpj,
          tipo,
          data_inicial: dataInicial ? moment(dataInicial).format('DD/MM/YYYY') : '',
          data_final: dataFinal ? moment(dataFinal).format('DD/MM/YYYY') : '',
        }
      });

      console.log(data)

      setQueues(data);
    }catch(err){
      console.log(err);
    }
    setLoading(false);
  }

  function isNumberString(value) {
      return /^\d{12,}$/.test(value);
  }
  

  const exportToCSV = async (item) => {

    try{
      let csvData = queues.map(obj => {
          const newObj = Object.assign({}, obj);
          delete newObj.id;
          delete newObj.fantasia_destinatario;
          delete newObj.createdAt;
          delete newObj.updatedAt;
          return '"' + Object.values(newObj).map(value => isNumberString(value) ? "'" + value : value).join('";"') + '"';
      });
      
      csvData.unshift(Object.keys(queues[0]).filter(key => key in alias).map(k => alias[k]).join(';'));
      
      const blob = new Blob([`\uFEFF${csvData.join('\n')}`], { type: 'text/csv;charset=utf-8;' });
      const csvURL = URL.createObjectURL(blob);
    
  
      FileSaver.saveAs(csvURL, moment().format('DD_MM_YYYY_HH_mm_ss') + '_nfes.csv');
    }catch(e){
      console.log(e);
    }
  }

  const deleteStablishment = async (id) => {
    try{
      if(window.confirm('Deseja apagar essa farmácia?') == true){
        await api.delete('/stablishments', {
          params:{
            id
          }
        });
        fetchData();
      }
    }catch(e){
      console.log(e);
    }
  }

  const selectAll = () => {
    setQueues(prev => {
      if(prev.every(p => p.selected)){
        return prev.map(p => ({...p, selected: false}));
      }else{
        return prev.map(p => ({...p, selected: true}));
      }
    })
  }

  const deleteSelected = async () => {
    const selected = queues.filter(p => p.selected).map(p => p.stablishmentId).join(',')
    
    if(!selected){
       return
    }

    await deleteStablishment(selected);
  }

  const handleSelection = (stablishmentId, checked) => {
    setQueues(prev => {
      const up = [...prev];
      up.find(p => p.stablishmentId === stablishmentId).selected = checked
      return up;
    });
  }

  const clearFilters = () => {
    setCnpj('');
    setTipo('');
    setDataInicial('');
    setDataFinal('');
    fetchData()
  }

  return <Container>
    <div className="d-flex align-items-center justify-content-between flex-wrap gap-3">
      <h5 className="section-title mb-0" style={{fontWeight: 700}}>Consultar  NFEs</h5>
    </div>

    <Section className="mt-3">
      {/* <InputSearch
        search={search}
        onChange={setSearch}
        onSubmitSearch={() => fetchData(null)}
      /> */}
      <div className="row">
        <InputMask
          mask={'99.999.999/9999-99'}
          label={'CNPJ'}
          className="col-6 col-sm-3 input-sm"
          containerStyle={{marginTop: 5}}
          value={cnpj}
          onChange={setCnpj}
          placeholder={'CNPJ'}
        />
        <InputGroup
          label={'Tipo'}
          className="col-6 col-sm-3 input-sm"
          containerStyle={{marginTop: 5}}
          type="select"
          value={tipo}
          onChange={setTipo}
          options={[{ title: 'Todos', value: ''}, { title: 'Emissor', value: 'emissor'}, { title: 'Destinatário', value: 'destinatario'}]}
        />
        <InputGroup
          className="col-6 col-sm-3 input-sm"
          containerStyle={{marginTop: 5}}
          label={'Data Inicial'}
          type="date"
          value={dataInicial}
          onChange={(v)=>setDataInicial(v)}
          placeholder={'Data Inicial'}
        />
        <InputGroup
          className="col-6 col-sm-3 input-sm"
          containerStyle={{marginTop: 5}}
          label={'Data Final'}
          required
          type="date"
          value={dataFinal}
          onChange={(v)=>setDataFinal(v)}
          placeholder={'Data Final'}
        />
      </div>
      <div className="text-end">
        <Button className="btn-neutral mt-3" onClick={clearFilters} style={{borderRadius: 14, fontSize: 14, fontWeight: 600,}}>Limpar filtros &nbsp;<FontAwesomeIcon icon="times" /></Button>
        <Button className="btn-primary mt-3" onClick={fetchData} loading={loading} style={{borderRadius: 14, fontSize: 14, fontWeight: 600,}}>Pesquisar &nbsp;<FontAwesomeIcon icon="search" /></Button>
      </div>

      <div className="justify-content-end d-flex align-items-center flex-row mt-3" style={{gap: '10px', flexWrap: 'wrap'}}>
        <small>Total de {queues.length} notas registradas</small>
        <Button disabled={!queues.length} className="btn-primary" onClick={exportToCSV} style={{borderRadius: 14, fontSize: 14, fontWeight: 600,}}>Exportar para CSV &nbsp;<FontAwesomeIcon icon="file" /></Button>
      </div>


      {loading ?
      <Spinner/>
      :
      (queues.length ?
        <DataTable
          className="mt-3"
          columns={columns}
          setColumns={setColumns}
          data={queues}
          onSubmitSearch={(v) => fetchData()}
          // topActions={[
          //   <Button loadingText={'Aguarde! Exportando...'} className={'btn-primary'} onClick={()=>selectAll()}>
          //     { 
          //       'Selecionar todos' 
          //     }
          //   </Button>,
          //   <Button loadingText={'Aguarde! Exportando...'} className={'btn-primary'} onClick={()=>exportAll()}>
          //     { 
          //       queues.some(p => p.selected) ? 'Exportar ' + queues.filter(p => p.selected).length + ' selecionados' : 'Exportar todos' 
          //     }
          //   </Button>,
          //   queues.some(p => p.selected) && <Button loading={deleteSelectedLoading} style={{color: 'red'}} loadingText={'Aguarde! Excluindo...'} className={'btn-outline'} onClick={()=>deleteSelected()}>
          //     { 
          //       'Excluir ' + queues.filter(p => p.selected).length + ' selecionados'
          //     }
          //   </Button>,
          // ]}
          // onSort={fetchData}
          page={page}
          onChangePage={setPage}
        />
      :
      <div className="text-center">
        <FontAwesomeIcon icon={'table-list'} fontSize={34} color={colors.muted} />
        <h6 className="mt-3"><b>Nenhum registro</b></h6>
      </div>
      )
      }
    </Section>


  </Container>
}

export default Consulta;