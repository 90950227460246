import styled from "styled-components";
import { colors } from "../../constants/colors";


export const Container = styled.div`
  .pagination-text{
    font-size: 0.85rem; 
  }

  .pagination{
    .page-left .page-link {
      border-top-left-radius: 10px;
      border-bottom-left-radius: 10px;
    }
    .page-right .page-link {
      border-top-right-radius: 10px;
      border-bottom-right-radius: 10px;
    }

    .page-item .page-link{
      aspect-ratio: 1;
      padding: 0;
      width: 35px;
    }
    .page-link{
      &:focus,&:disabled{
        box-shadow: none;
        background-color: white;
      }
      color: black;

      &.active{
        background-color: ${colors.lightPrimary};
        color: ${colors.primaryColor};
        /* color: white; */
      }

      &:disabled{
        color: ${colors.muted};
      }
    }
  }

  thead th{
    .column{
      text-decoration: none;
      color: ${colors.muted};
      display: inline-flex;
      align-items: center;
      gap: 5px;
      font-weight: 600;
    }
    .sort-buttons{
      font-size: 7px;
      display: inline-flex;
      flex-direction: column;
      opacity: 0.3;
    }
  }

  tr{
    font-size: 0.85rem;
    font-weight: 500;

  }
  table.table-hover tr:hover {
    cursor: pointer;
  }

  .table-borderless tr {
    border-bottom: 1px solid #eee;
    /* --bs-table-accent-bg: none; */
  }

`;