import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Backdrop, Container } from "./styles";
import { Fragment, useEffect, useRef, useState } from "react";
import Sidebar from "../Sidebar";
import { colors } from "../../constants/colors";
import api from "../../services/api";
import CaptchaModal from "../CaptchaModal";

const Header = () => {
   
  const [ sidebarOpen, setSidebarOpen ] = useState(false);
  const [ captchaOpen, setCaptchaOpen ] = useState(false);

  
  const [ captcha, setCaptcha ] = useState();

  const interval = useRef();

  useEffect(() => {
    fetchCaptchaStatus();
    checkConnection();

    return () => interval.current && clearInterval(interval.current)
  }, []);

  const checkConnection = () => {
    interval.current = setInterval(fetchCaptchaStatus, 3000);
  }

  const fetchCaptchaStatus = async () => {
    try{
      const { data } = await api.get('captcha');
      setCaptcha(data);
    }catch(e){}
  }

  return <Fragment>
    <Container className={sidebarOpen ? 'open' : ''}>
      <nav className="container-fluid d-flex justify-content-between align-items-center">
        <div className="d-flex align-items-center" style={{gap: 10}}>
          <a role="button" onClick={() => setSidebarOpen(true)}>
            <FontAwesomeIcon icon={'bars'} color={colors.primaryColor} />
          </a>
          <b>Fisco Sefaz</b>
        </div>
        <button onClick={() => captcha?.status != 'success' && setCaptchaOpen(true)} className={`btn btn-neutral captcha-validation ${captcha?.status == 'success' ? 'active' : 'inactive'}`}>
          <FontAwesomeIcon icon="qrcode"/> {captcha?.status == 'success' ? 'Captcha ON' : 'Captcha OFF'}
        </button>
      </nav>
    </Container>
    <Sidebar open={sidebarOpen} onClose={()=>setSidebarOpen(false)}/>
    <CaptchaModal data={captcha} open={captchaOpen} onClose={()=>setCaptchaOpen(false)}/>
    <Backdrop className={(sidebarOpen) ? 'active' : ''} />
  </Fragment>;
}

export default Header;