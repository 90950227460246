import { Container } from "./styles"


export const InputGroup = ({name, type = 'text', className = '', containerStyle = {}, label, options, value, onChange, placeholder, id = name, ...props}) =>{

  const inputType = (v) => {
    if(type == 'checkbox'){
      return { checked: v }
    }
    
    return {}
  }

  return <Container className={"form-group " + className} style={containerStyle}>
    <label htmlFor={id}>{label}</label>
    
    {type != 'select' ?
    <input {...props} value={value} type={type} {...inputType(value)} placeholder={placeholder} onChange={e => onChange(type != 'checkbox' ? e.target.value : e.target.checked)} name={name} id={id} className={type == 'checkbox' ? '' : "form-control"} />
    :
    <select value={value} className={'form-control'} onChange={e => onChange(e.target.value)}>
      {options.map(op => <option value={op.value}>{op.title}</option>)}
    </select>
    }
  </Container>
}

    
