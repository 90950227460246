import React from "react";
import { colors } from "../../../constants/colors";

export const CardInfo = ({title, icon, value, description}) => {

  return <div className="card p-3 shadow-sm d-flex" style={{flex: 1, minWidth: 200, borderRadius: 10}}>
  <div className="d-flex align-items-center justify-content-between">
      <h6 className="card-title m-0">{title}</h6>
      <div className="d-flex align-items-center justify-content-center" style={{width: 40, height: 40, borderRadius: 5, background: colors.primaryColor+'30'}}>
      {icon}
      </div>
  </div>
  <p className="mt-1 m-0" style={{fontWeight:700, fontSize: 36, color: '#333'}}>{value}</p>
  <p className="m-0" style={{fontWeight: 400, fontSize: 13, color: colors.muted}}>{description}</p>
</div>;

}