import React, { useState } from "react";
import { colors } from "../../../constants/colors";
import Button from "../../../components/Button";
import DataTable from "../../../components/DataTable";
import moment from "moment";
import Spinner from "../../../components/Spinner";

const statusList = {
    pending: {
      class: 'alert-warning',
      title: 'Pendente'
    },
    processing: {
      class: 'alert-info',
      title: 'Em progresso'
    },
    completed: {
      class: 'alert-success',
      title: 'Sucesso'
    },
    canceled: {
      class: 'alert-danger',
      title: 'Cancelado'
    },
  }

function Listagemcnpj ({onRetry, queue, cnpjs})   {
    // const cnjps = [
    //     { cnpj: '12345678901234', tentativas: 3, status: 'Pendente' },
    //     { cnpj: '98765432109876', tentativas: 1, status: 'Completo' }
    // ];
    // function renderizarCNPJs() {
    //     const tbody = document.querySelector('#cnjp-table tbody');

    //     tbody.innerHTML = '';
    //     cnjps.forEach(cnpj => {
    //         const row = document.createElement('tr');

    //         row.innerHTML = `
    //             <td>${cnpj.cnpj}</td>
    //             <td>${cnpj.tentativas}</td>
    //             <td>${cnpj.status}</td>
    //             <td>
    //                 ${cnpj.status !== 'Completo' ? `<button onclick="tentarNovamente('${cnpj.cnpj}')">Tentar novamente</button>` : '' }
    //             </td>            
                    
    //             `;
    //             tbody.appendChild(row);
    //     });
    // }
    // function tentarNovamente(cnpj) {
    //     console.log(`Tentando novamente o CNPJ ${cnpj}`);
    //     // Aqui você pode adicionar lógica para tentar novamente o CNPJ
    //     // Por exemplo, fazer uma requisição AJAX para tentar novamente
    //   }
    
    //   // Chamar a função para renderizar a lista de CNPJs quando a página carregar
    //   window.onload = function() {
    //     renderizarCNPJs();
    //   }

    // return (
    //     <table id="cnjp-table">
    //     <thead>
    //       <tr>
    //         <th>CNPJ</th>
    //         <th>Tentativas</th>
    //         <th>Status</th>
    //         <th>Ações</th>
    //       </tr>
    //     </thead>
    //     <tbody>
    //     {renderizarCNPJs()}
    //     </tbody>
    //   </table>
      
    // )
    const [selectedCnpj, setSelecteadCnpj] = useState(null);

     const handleTryAgain = (cnpj) => {
         console.log(`seu ${cnpj}`);
     };

     const columns = [
         {
             title: "",
             key: "status",
             render: (item) => <>
                {item.status == 'pending' && queue.status == 'pending' && item.retry < 3 ?
                <Spinner/>
                :null}
             </>
            //  sortable: true,
         },
         {
             title: "CNPJ",
             key: "cnpj",
            //  sortable: true,
         },
         {
             title: "UF",
             key: "uf",
            //  sortable: true,
         },
         {
             title: "Data Inicial",
             key: "startDate",
             render: (item) => (<>
                {moment(item.startDate).format('DD/MM/YYYY')}
             </>)
            //  sortable: true,
         },
         {
             title: "Data Final",
             key: "endDate",
             render: (item) => (<>
                {moment(item.endDate).format('DD/MM/YYYY')}
             </>)
            //  sortable: true,
         },
         {
             title: "Tentativas",
             key: "retry",
            //  sortable: true,
         },
         {
             title: "Status",
             key: "status",
             render: (item) => (<>
                <span class={`badge ${statusList[item.status].class}`} >{statusList[item.status].title}</span>
              </>)
            //  sortable: true,
            //   cell: (row) => (
            //       <div>
            //         {row.status}
            //           {row.status !== "sucesso " && (
            //               <Button onClick={() => handleTryAgain(row.cnpj)} >Tentar novamente</Button>
            //           )}
            //       </div>
            //   ),
         },

     ];

     return (

         <DataTable
             title="Lista de CNPJs"
             columns={columns}
             data={cnpjs}
             pagination
             selectableRows
             // onSelectedRowsChange={(selectableRows) => {
             //     setSelecteadCnpj(selectableRows[0]?.cnpj);
             // }}
         />

     );
 };
   
 

export default Listagemcnpj;