import styled from "styled-components";
import { colors } from "../../constants/colors";

export const Container = styled.div`
  height: 10px;
  overflow: unset;
  .progress-bar{
    background-color: ${colors.success};
    overflow: unset;
    border-radius: .25rem;
    position: relative;
    &::after{
      content: ${(props) => props?.total ? `'${props.completed}/${props.total}'` : "''"};
      color: ${colors.muted};
      position: absolute;
      right: 0px;
      top: 10px;
      font-size: 8px;
    }
  }
`;
