import React from "react";
import { Container } from "./styles";

const Progress = ({ percent, total, completed }) => {

  return <Container className="progress" total={total} completed={completed}>
    <div className="progress-bar" role="progressbar" style={{width: percent + '%'}} aria-valuenow={percent} aria-valuemin="0" aria-valuemax="100"></div>
  </Container>

};

export default Progress;