import ReactInputMask from "react-input-mask"
import { Container } from "./styles"


export const InputMask = ({name, mask, type = 'text', className = '', containerStyle = {}, label, options, value, onChange, placeholder, id = name, ...props}) =>{

  const inputType = (v) => {
    if(type == 'checkbox'){
      return { checked: v }
    }
    
    return {}
  }

  return <Container className={"form-group " + className} style={containerStyle}>
    <label htmlFor={id}>{label}</label>
    <ReactInputMask mask={mask} {...props} value={value} type={type} {...inputType(value)} placeholder={placeholder} onChange={e => onChange(type != 'checkbox' ? e.target.value : e.target.checked)} name={name} id={id} className={type == 'checkbox' ? '' : "form-control"} />
    
  </Container>
}