import React, { useEffect, useState } from 'react';
import './App.css';
import { BrowserRouter, Navigate, Route, Routes, redirect, useNavigate } from 'react-router-dom';
import Main from './screens/Main';

import { library } from '@fortawesome/fontawesome-svg-core';
import { fas } from '@fortawesome/free-solid-svg-icons';
import Point from './screens/Point/List';
import Users from './screens/Users/List';
import EditPoint from './screens/Point/Edit';
import EditUser from './screens/Users/Edit';
import Login from './screens/Auth/Login';
import jwtDecode from 'jwt-decode';
import Imports from './screens/Imports/List';
import EditImport from './screens/Imports/Edit';
import Settings from './screens/Settings';
import Consulta from './screens/Consulta/List';
import Detalhes from './screens/Detalhes';
// Add the Font Awesome icons to the library
library.add(fas);

function App() {

  // const navigate = useNavigate();

  return (
    <BrowserRouter>
      <Routes>
        <Route path='/' element={<Main/>}>
          <Route path='' element={<Navigate to="/importacoes"/>}/>
          <Route path='/importacoes' element={<Imports/>} />
          <Route path='/importacoes/novo' element={<EditImport/>} />
          <Route path='/importacoes/:id' element={<Detalhes/>} />
          <Route path='/configuracoes' element={<Settings/>} />
          <Route path='/consulta-nfes' element={<Consulta/>} />
          {/* <Route path='/usuarios' element={<Users/>} /> */}
          {/* <Route path='/usuarios/novo' element={<EditUser/>} /> */}
          <Route path='*' element={<Navigate to="/"/>}/>
          <Route  path="/detalhes" element={<Detalhes/>} />
        </Route>
        {/* <Route path='/login' element={<Login />}/> */}
      </Routes>
    </BrowserRouter>
  );
}

export default App;
