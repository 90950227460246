import styled from "styled-components";
import { colors } from "../../constants/colors";

export const Container = styled.section`

    .bulk-wrapper {
        max-height: 400px;
        overflow: auto;
        margin-top: 1rem;
        margin-bottom: 1rem;
    }

  .bulk-container{
    padding: 0px 0 1.5rem 0;
    margin: 10px 0px;
    border-radius: 14px;
    border: 1px dashed ${colors.primaryColor}66;
  }

  .last-update{
    font-size: 14px;

  }
    .color-primary {
        color: ${colors.primaryColor}
    }
    .cont-card {
        width: 100px;
        height: 100px;
        flex: 1;
        margin: 0 5px;
    }
`;